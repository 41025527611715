<template>
    <div class="cont_main">
        <div class="cont_catalogos w-full md:w-4/6 xl:w-3/6 2xl:w-2/6">
            <router-link class="catalogo " :class="cat.proximamente ? 'proximamente' : ''" :to="cat.dir" v-for="(cat, index) in menu" :key="index">
                <div class="cont_portada">
                    <img :src="cat.img" alt="">
                </div>
                <div class="texto">{{ cat.texto }}</div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "menuCampañas",
    data(){
        return{
            menu: []
        }
    },
    methods: {

    },
    created(){
        let fetchPromise = fetch("json/menu.json");

        fetchPromise
            .then(response => {
                return response.json();
            }).then(data => {
                this.menu = data;
            });
    },
    computed:{
        
    }
}
</script>

<style>
.cont_main {
    position: fixed;
    display: block;
    height: 100%;
    width: 100%;
    margin-top: 4rem;
    z-index: 1;
    transition: .3s ease-in-out;
    overflow: scroll;
}
.cont_catalogos {
    display: flex;
    margin: 1rem auto;
    padding-bottom: 10rem;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
}
.catalogo {
    transition: 0.3s ease-in-out;
    display: inline-block;
    padding: 0.5rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0 5%;
    margin-top: 20rem;
    color: white;
    cursor: pointer;
    width: 14rem;
    border-radius: 0.5rem;
    background: rgba(249, 58, 90, 1);
    box-shadow: 0rem 0rem 1rem 0.1rem rgba(249, 58, 90, 1);
}
.catalogo:hover {
    transform: scale(1.05);
    box-shadow: 0rem 0rem 1.4rem 0.3rem rgb(249, 58, 90, 0.5);
}
.texto {
    width: 100%;
    text-align: center;
}
.cont_portada {
    position: absolute;
    display: block;
    width: 12rem;
    margin-top: -18rem;
    transform: scale(1) rotate(5deg);
    border: solid 2px white;
    transition: .3s ease-in-out;
    box-shadow: 0rem 0rem 1rem 0.1rem rgba(249, 58, 90, 0.5);
}
.catalogo:hover .cont_portada {
    transform: scale(1.1) rotate(0deg);
    box-shadow: 0rem 0rem 1.4rem 0.3rem rgb(249, 58, 90, 0.5);
}
.proximamente {
    transform: scale(0.9);
}
.proximamente:before {
    position: absolute;
    top: -20rem;
    display: flex;
    content: "PRÓXIMAMENTE";
    font-weight: 600;
    color: #f93a5a;
    align-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    margin: 0rem 2rem;
}
.catalogo:hover {
    transform: scale(1.05);
    box-shadow: 0rem 0rem 1.4rem 0.3rem rgb(249, 58, 90, 0.5);
}
</style>