<template>
  <div class="home">
    <menuCampañas />
  </div>
</template>

<script>
import menuCampañas from "@/components/menuCampañas.vue"
import { mapMutations } from 'vuex';

export default {
  name: "Inicio",
  components: {
    menuCampañas,
  },
  data(){
    return{

    }
  },
  methods: {
    ...mapMutations(["setCampaña"])
  },
  created(){
    this.setCampaña("")
  },
  computed: {

  }
};
</script>
