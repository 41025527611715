import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    showShopping: false,
    productosShopping: [],
    productosFinal: [],
    
    campaña: "",
    showMenu: true,

    pagActual: 1,
    totalPaginas: 0,
  },
  getters: {
  },
  mutations: {
    setProductoShopping(state, obj){
      //console.log(state.productosShopping)
      obj.cantidad = 1
      let aux = state.productosShopping.filter((x) => x.numCodigo == obj.numCodigo)
      if(aux.length < 9){
        state.productosShopping.push(obj)
      }
      aux = state.productosShopping.filter((x) => x.numCodigo == obj.numCodigo)
      if(aux.length == 1){
        state.productosFinal.push(obj)
      }
    },
    deleteProductoShopping(state, obj){
      let objaux, cant, cont
      objaux = state.productosShopping.filter((x) => x.numCodigo == obj.numCodigo)
      cant = objaux.length
        if(objaux.length == 1){
          if(cant == 1){
            state.productosShopping.forEach((element) => {
              if (element == obj) {
                for (var i = 0; i < state.productosShopping.length; i++){
                  if (state.productosShopping[i].numCodigo == obj.numCodigo) {
                  state.productosShopping.splice(i, 1)
                    state.productosFinal.forEach((element) => {
                      if (element.numCodigo == obj.numCodigo) {
                        for (var i = 0; i < state.productosFinal.length; i++){
                          if (state.productosFinal[i].numCodigo === obj.numCodigo) {
                            state.productosFinal.splice(i, 1)
                          }
                        }
                      }
                    })
                  }
                }
              }
            })
          }
        }else{
          state.productosShopping.forEach((element) => {
            if (element == obj) {
              for (var i = 0; i < state.productosShopping.length; i++){
                if (state.productosShopping[i].numCodigo == obj.numCodigo) {
                  while (cant > 0){
                    cont = false
                    if(cont == false){
                      cont = true
                      state.productosShopping.splice(i, 1)
                      objaux = state.productosShopping.filter((x) => x.numCodigo == obj.numCodigo)
                      cant = objaux.length
                    }
                    if(cant == 0){
                      state.productosFinal.forEach((element) => {
                        if (element.numCodigo == obj.numCodigo) {
                          for (var i = 0; i < state.productosFinal.length; i++){
                            if (state.productosFinal[i].numCodigo === obj.numCodigo) {
                              state.productosFinal.splice(i, 1)
                            }
                          }
                        }
                      })
                    }
                  }
                }
              }
            }
          })
        }
    },
    toggleShowShopping(state){
      if(state.showMenu){
        state.showMenu = false
      }
      state.showShopping = !state.showShopping
    },
    toggleShowMenu(state){
      if(state.showShopping){
        state.showShopping = false
      }
      state.showMenu = !state.showMenu
    },
    setPagActual(state, num){
      state.pagActual = num
    },
    setTotalPaginas(state, num){
      state.totalPaginas = num
    },
    restaCantidad(state, obj){
      let objaux,cont, cant
      cont = false
      objaux = state.productosShopping.filter((x) => x.numCodigo == obj.numCodigo)
      cant = objaux.length
      state.productosShopping.forEach((element) => {
        if (element == obj) {
          for (var i = 0; i < state.productosShopping.length; i++){
            if (state.productosShopping[i].numCodigo == obj.numCodigo) {
              if(cont == false){
                cont = true
                state.productosShopping.splice(i, 1)

                objaux = state.productosShopping.filter((x) => x.numCodigo == obj.numCodigo)
                cant = objaux.length

                if(cant == 0){
                  state.productosFinal.forEach((element) => {
                    if (element.numCodigo == obj.numCodigo) {
                      for (var i = 0; i < state.productosFinal.length; i++){
                        if (state.productosFinal[i].numCodigo === obj.numCodigo) {
                          state.productosFinal.splice(i, 1)
                        }
                      }
                    }
                  })
                }
              }
            }
          }
        }
      })
    },
    setCampaña(state, camp){
      state.campaña = camp

      state.showShopping = false
      if(camp == ""){
        state.productosShopping = []
        state.productosFinal = []
      }
      state.showMenu = false

      state.pagActual = 1
      state.totalPaginas = 0
    },
    togglePopUp(state){
      if(state.mostrarPopUp == true){
        state.mostrarPopUp = false
      }else{
        state.mostrarPopUp = true
      }
      console.log(state.mostrarPopUp)
    }
  },
  actions: {
  },
  modules: {
  }
})
