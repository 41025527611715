import Vue from "vue";
import VueRouter from "vue-router";
import Inicio from "../views/Inicio.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Inicio,
  },
  {
    path: "/:camp",
    name: "Seleccion",
    component: () =>
      import("../views/Seleccion.vue"),
  },
  {
    path: "/Catalogo/:camp",
    name: "Catalogo",
    component: () =>
      import("../views/Catalogo.vue"),
  },
  {
    path: "/Ofertas/:camp",
    name: "Ofertas",
    component: () =>
      import("../views/Ofertas.vue"),
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
