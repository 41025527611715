<template>
  <div>
    <nvar class="fixed w-full top-0 z-50"/>
    <shopping class="top-0 z-50" :style="this.$store.state.showShopping ? 'right: 0rem' : 'right: -30rem' "/>
    <router-view />
    <footer>
      <a href="https://juanabapl.juanabonita.com.co:446/ped_web/pgIngresoAsesora.aspx">Quiero Vender</a>
    </footer>
  </div>
</template>

<script>
import nvar from "@/components/nvar.vue";
import shopping from "@/components/shopping.vue";
import { mapState } from 'vuex';

export default{
  name: "App",
  components: {
    nvar,
    shopping,
  },
  data(){
    return{
      
    }
  },
  created(){
    
  },
  computed: {
    ...mapState(["campaña"])
  }
}
</script>

<style>
*{
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
*:focus{
  outline: none;
}
body{
  width: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
footer {
  position: fixed;
  display: flex;
  bottom: 0px;
  width: 100%;
  padding: 0.5rem 0rem;
  background: #f93a5a;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  z-index: 99;
}
footer a{
  color: #fff;
  cursor: pointer;
}
footer a:hover{
  text-decoration: underline;
}
</style>
